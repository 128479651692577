import { getAsync, postMultiFormDataAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export interface IGetReportRequest extends IBodyRequestModel {
  id: string
}

export const getReport = TokenExpiryWrapper(
  async (request: IGetReportRequest): Promise<IResponse<string>> => {
    const url = `/report/${request.id}`
    return await getAsync(url, request)
  },
  [],
  null,
)

export interface IUploadRequest extends IBodyRequestModel {
  projectName: string
  clientName: string
  query: string
  files: File
}

export interface IUploadResponse {
  success: boolean
  message?: string
  wait?: number
}

export const submitReport = TokenExpiryWrapper(
  async (request: IUploadRequest): Promise<IUploadResponse | null> => {
    try {
      const url = `/report`
      const formData = new FormData()
      Object.keys(request)
        .filter((key) => key !== 'token')
        .forEach((key) => {
          formData.append(key, request[key] || '')
        })

      return await postMultiFormDataAsync(url, request.token || '', formData)
    } catch (error) {
      console.log('** Error fetching data...')
      console.log(error)
    }
    return null
  },
  [],
  null,
)
