import { FC, useState } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import CookiesPolicy from './pages/CookiesPolicy'
import Login from './pages/Login'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import NotFound from './pages/NotFound'
import { useAuth0 } from '@auth0/auth0-react'
import Page from './components/Page'
import LoadingScreen from './components/LoadingScreen'
import { RecoilRoot } from 'recoil'
import Consent from './pages/Consent'
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'
import TermsCookiesModal from './components/TermsCookiesModal'
import Profile from './pages/Profile'
import Home from './pages/Home'
import Report from './pages/Report'

import ErrorGfx from './assets/kitten.svg'

import Style from './styles/App.module.sass'

const App: FC = () => {
  const { isLoading, error, isAuthenticated } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)

  if (error) {
    return (
      <div className={Style.errorWrapper}>
        <div className={Style.innerWrapper}>
          <p>Aurecon WinWise</p>
          <p>
            <img src={ErrorGfx} alt='Error' />
          </p>
          <p>Oops... Looks like we have an issue...</p>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Page>
        <LoadingScreen text='Loading application...' />
      </Page>
    )
  }

  const publicRoutes = [
    <Route key='home_public' path='/login' element={<Login />} />,
    <Route key='terms' path='/termsandconditions' element={<TermsAndConditions />} />,
    <Route key='privacy' path='/privacy' element={<PrivacyPolicy />} />,
    <Route key='cookies' path='/cookies' element={<CookiesPolicy />} />,
  ]

  const privateRoutes = [
    <Route key='profile' path='/profile' element={<Profile />} />,
    <Route key='report' path='/report/:id' element={<Report />} />,
    <Route key='root' path='/' element={<Home />} />,
  ]

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter>
          <Routes>
            {publicRoutes}
            <Route path='/' element={<Navigate to='/login' state={location} />} />
          </Routes>
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  // In the case with two routes with the same path, private will override public
  return (
    <RecoilRoot>
      <ToastProvider>
        <HashRouter>
          <Routes>
            {privateRoutes}
            {publicRoutes}
            <Route path='*' element={<NotFound />} />
          </Routes>
          <TermsCookiesModal />
        </HashRouter>
        <Toast />
      </ToastProvider>
    </RecoilRoot>
  )
}

export default App
