import { FC, useMemo, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import { useAuth0 } from '@auth0/auth0-react'
import { useMediaQuery } from 'react-responsive'

import RecallLogo from '../../src/assets/Recall_logo.svg'
import WinwiseLogo from '../../src/assets/WinWise_logo.svg'
import WinwiseLogoSmall from '../../src/assets/WinWise_logo_small.svg'
import WinWiseLogo from '../../src/assets/WinWise_logo.svg'
import ChatGPTLogo from '../../src/assets/ChatGPT_app_icon.svg'

import config, { UI_WIDTH_COLLAPSE } from '../config/config'
import { AppVersion } from '../stores/AppStore'

import Style from '../styles/Menu.module.sass'

const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`

const Menu: FC = () => {
  const appVersion = useRecoilValueLoadable(AppVersion)
  const { logout } = useAuth0()
  const isNotMobile = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })

  const [showApps, setShowApps] = useState(false)
  const [overApps, setOverApps] = useState(false)

  const version = useMemo(() => {
    if (appVersion.state !== 'hasValue') return '0.0.0'

    return appVersion.contents
  }, [appVersion])

  const handleHome = () => {
    location.hash = `#/`
  }

  const handleLogout = () => {
    logout({ returnTo })
  }

  const handleOpenApps = () => {
    setShowApps(true)
  }

  const handleCloseApps = (force: boolean) => {
    if (force) setOverApps(false)
    if (overApps && !force) return

    setShowApps(false)
  }

  const openApp = (appName: string) => {
    setShowApps(false)

    switch (appName) {
      case 'recall':
        location.href = `https://recall.aurecongroup.digital/`
        break

      case 'chatGpt':
        location.href = `https://recall.aurecongroup.digital/#/chat-gpt`
        break

      case 'winwise':
        location.hash = `#/`
        break

      default:
        break
    }
  }

  const appsMenu = (
    <div
      className={Style.appsMenuWrapper}
      onMouseEnter={() => setOverApps(true)}
      onMouseLeave={() => handleCloseApps(true)}
    >
      <div className={Style.appTile} onClick={() => openApp('recall')}>
        <img src={RecallLogo} alt='Recall Logo' />
      </div>
      <div className={Style.appTile} onClick={() => openApp('winwise')}>
        <img src={WinWiseLogo} alt='WinWise Logo' />
      </div>
      <div className={Style.appTile} onClick={() => openApp('chatGpt')}>
        <img src={ChatGPTLogo} alt='ChatGPT Logo' />
      </div>
      <div className={Style.arrow} />
    </div>
  )

  return (
    <div className={Style.menu}>
      <img src={isNotMobile ? WinwiseLogo : WinwiseLogoSmall} alt='Recall Logo' className={Style.recallLogo} />
      <div className={Style.menuItem} onClick={handleHome}>
        <Icon type='home' outlined />
        {isNotMobile && ' Home'}
      </div>
      <div className={Style.menuItem} onMouseEnter={() => handleOpenApps()} onMouseLeave={() => handleCloseApps(false)}>
        <Icon type='dashboard' outlined />
        {isNotMobile && ' Apps'}
        {showApps && appsMenu}
      </div>
      <div className={Style.menuItem} onClick={handleLogout}>
        <Icon type='logout' />
        {isNotMobile && ' Logout'}
      </div>

      <div className={Style.menuVersion}>
        <div className={Style.text}>
          {isNotMobile && 'version '}
          {version}
        </div>
      </div>
      <div className={Style.terms}>
        <a href='/#/termsandconditions'>{isNotMobile ? 'Terms & Conditions' : 'T&C'}</a>
      </div>
    </div>
  )
}

export default Menu
