import { FC, useEffect, useRef, useState } from 'react'
import * as ReactDOM from 'react-dom/client'
import { useParams } from 'react-router-dom'
import { Loader } from '@aurecon-creative-technologies/styleguide'
import ReactMarkdown from 'react-markdown'

import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'
import { getReport } from '../api/ReportService'

import Style from '../styles/Report.module.sass'

interface IFaq {
  question: string
  answer: string
}

interface IPeople {
  Evaluation: string
  Link: string
  Location: string
  Rationale: string
  Name: string
}

interface IReport {
  name: string
  client: string
  date: string
  summary: string
  skills: string
  faq: IFaq[]
  people: IPeople[]
}

interface IRefDiv {
  [label: string]: HTMLDivElement | null
}

const INITIAL_REFS = {
  summary: null,
  skills: null,
}

const cleanText = (text: string) => {
  let cleanText = text
  cleanText = cleanText.replace(/\n- /g, '\n  * ')
  cleanText = cleanText.replace(/\n/g, '\n\n')
  cleanText = cleanText.replace(/The output of the extraction would be:/g, '')
  cleanText = cleanText.replace(/The extraction would be:/g, '')
  if (cleanText.startsWith('• ')) cleanText = cleanText.replace('• ', '* ')
  if (cleanText.startsWith('- ')) cleanText = cleanText.replace('- ', '  * ')

  return cleanText
}

const Report: FC = () => {
  const { id } = useParams<string>()
  const [reportData, setReportData] = useState<IReport | null>(null)
  const [noReport, setNoReport] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const refs = useRef<IRefDiv>(INITIAL_REFS)

  if (appInsights) appInsights.trackPageView({ name: 'Report' })

  useEffect(() => {
    const asyncFunc = async () => {
      if (!id) return
      setLoading(true)

      const response = await getReport({ id })

      setLoading(false)

      if (!response?.data) {
        setNoReport(true)
        setReportData(null)
        return
      }

      const evalution_fix = response.data.replace(/\\n\\nEvaluation: /g, '", "Evaluation": "')

      const jsonData = JSON.parse(evalution_fix)
      setNoReport(false)
      setReportData(jsonData)
    }

    asyncFunc()
  }, [id])

  useEffect(() => {
    if (!refs.current.skills || !refs.current.summary || !reportData) return
    let root = ReactDOM.createRoot(refs.current.summary)
    root.render(<ReactMarkdown>{cleanText(reportData.summary)}</ReactMarkdown>)

    root = ReactDOM.createRoot(refs.current.skills)
    root.render(<ReactMarkdown>{cleanText(reportData.skills)}</ReactMarkdown>)

    if (!refs.current.faq0) return

    reportData.faq.map((f, index) => {
      const propName = `faq${index}`
      if (!refs.current[propName]) return

      root = ReactDOM.createRoot(refs.current[propName] as Element)
      root.render(<ReactMarkdown>{cleanText(reportData.faq[index].answer)}</ReactMarkdown>)
    })

    if (!refs.current.people0) return

    reportData.people.map((p, index) => {
      const propName = `people${index}`
      if (!refs.current[propName]) return

      root = ReactDOM.createRoot(refs.current[propName] as Element)
      root.render(<ReactMarkdown>{cleanText(reportData.people[index].Rationale)}</ReactMarkdown>)
    })
  }, [reportData])

  return (
    <Page menu>
      <div className={Style.page}>
        <div className={Style.content}>
          {loading && <Loader label='Loading report...' />}
          {noReport && <h2>No report found</h2>}
          {reportData && !loading && (
            <>
              <ol>
                <li className={Style.report}>Report Details</li>
                <p>Project name: {reportData.name}</p>
                <p>Client name: {reportData.client}</p>
                <p>Date generated: {reportData.date}</p>

                <li className={Style.report}>Summary</li>
                <div ref={(el) => (refs.current.summary = el)} />

                <li className={Style.report}>Skills</li>
                <div ref={(el) => (refs.current.skills = el)} />

                <li className={Style.report}>FAQ</li>
                {reportData.faq.map((f, index) => {
                  return (
                    <div key={index} className={Style.multiItem}>
                      <p>
                        <b>Question:</b>
                      </p>
                      <p>{f.question}</p>
                      <p>
                        <b>Answer:</b>
                      </p>
                      <div ref={(el) => (refs.current[`faq${index}`] = el)} />
                    </div>
                  )
                })}

                <li className={Style.report}>People</li>
                {reportData.people.map((p, index) => {
                  const link =
                    p.Link === 'N/A' ? (
                      p.Link
                    ) : (
                      <a href={p.Link} target='_blank' rel='noreferrer'>
                        {p.Link}
                      </a>
                    )

                  return (
                    <div key={index} className={Style.multiItem}>
                      <p>
                        <b>Name:</b> {p.Name}
                      </p>
                      <p>
                        <b>Evaluation:</b> {p.Evaluation}
                      </p>
                      <p>
                        <b>Location:</b> {p.Location}
                      </p>
                      <p>
                        <b>Link:</b> {link}
                      </p>
                      <p>
                        <b>Rationale:</b>
                      </p>
                      <div ref={(el) => (refs.current[`people${index}`] = el)} />
                    </div>
                  )
                })}
              </ol>
              <p className={Style.end}>End of report</p>
            </>
          )}
        </div>
      </div>
    </Page>
  )
}

export default Report
