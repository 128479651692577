import { FC } from 'react'

import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'

const NotFound: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'NotFound' })

  return (
    <Page>
      <div className='theme-navbar-text' style={{ textAlign: 'center' }}>
        <h1>404</h1>
        <h3>Not Found</h3>
      </div>
    </Page>
  )
}

export default NotFound
