import { FC, useMemo, useState } from 'react'
import { Button, Checkbox, CheckboxGroup, FormInput, Grid, Modal } from '@aurecon-creative-technologies/styleguide'

import Uploader from '../components/Uploader'
import Page from '../components/Page'
import { appInsights } from '../api/AppInsights'
import { submitReport } from '../api/ReportService'

import Style from '../styles/Home.module.sass'

const WAIT_TIME = 10 + Math.floor(Math.random() * 5)

const MAIN_QUERIES = [
  'What is the goal of the project?',
  'What are the key deliverables of the project?',
  'What are the key Client requirements for this project?',
  'What aspects does the Client value most for success in this project and how can we accommodate them?',
]

const Home: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Home' })
  const [documentUrl, setDocumentUrl] = useState('')
  const [filename, setFilename] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [projectName, setProjectName] = useState('')
  const [clientName, setClientName] = useState('')
  const [queueLength, setQueueLength] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [query, setQuery] = useState('')
  const [uploadError, setUploadError] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [mainQueries, setMainQueries] = useState<number[]>([])

  const formError = useMemo(() => {
    const errorMessage = []
    if (!projectName.trim()) errorMessage.push('project name')
    if (!clientName.trim()) errorMessage.push('client name')
    if (!uploadFile) errorMessage.push('pdf file')

    return errorMessage.length ? errorMessage.join(', ') : null
  }, [projectName, clientName, uploadFile])

  const onFileChange = (file: File[]) => {
    setUploadFile(file?.length ? file[0] : null)
    setDocumentUrl('')
    setFilename(file?.length ? file[0].name : '')
  }

  const handleCloseModal = () => {
    if (!uploadError) clearForm()

    setShowModal(false)
  }

  const handleQueryChange = (id: number, value: boolean) => {
    const newMainQueries = [...mainQueries]

    if (value && !newMainQueries.includes(id)) newMainQueries.push(id)
    if (!value && newMainQueries.indexOf(id) !== -1) newMainQueries.splice(newMainQueries.indexOf(id), 1)

    setMainQueries(newMainQueries)
  }

  const clearForm = () => {
    setProjectName('')
    setClientName('')
    setQuery('')
    setFilename('')
    setDocumentUrl('')
    setUploadFile(null)
    setQueueLength(0)
    setMainQueries([])
    setTrigger((value) => !value)
  }

  const handleSendRequest = async () => {
    if (!uploadFile) return

    setUploading(true)
    setShowModal(true)

    const combineMainQueries = MAIN_QUERIES.filter((q, index) => mainQueries.includes(index)).join('\n')
    const combineQueries = `${combineMainQueries}\n${query}`

    const result = await submitReport({
      projectName,
      clientName,
      query: combineQueries,
      files: uploadFile,
    })

    setUploading(false)
    setUploadError(!result?.success)

    if (result?.success) {
      setQueueLength(result?.wait || 0)
    }
  }

  return (
    <Page menu>
      <div className={Style.page}>
        <div className={Style.intro}>
          <h1>Welcome to WinWise</h1>
          <h2>Powerful document analysis tool</h2>
          <p>
            WinWise quickly processes Requests for Proposals or Tenders (RFPs & RFTs) and other documents to quickly
            generate a summary, extract key skills and answers to frequently asked questions. It then matches relevant
            people from the data in the CVs on our APEX digital platform. Soon we will also match relevant prior
            projects from APEX.
          </p>
          <p>
            Find more information on our hive pages{' '}
            <a
              href='https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/AI-at-Aurecon.aspx#winwise-a-powerful-document-analysis-tool.'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
            .
          </p>
        </div>
        <div className={Style.contents}>
          <Grid row gap={12}>
            <Grid item xs={12}>
              <FormInput
                label="Project's Name"
                placeholder="Enter project's name"
                required
                value={projectName}
                onChange={setProjectName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                label="Client's Name"
                placeholder="Enter client's name"
                required
                value={clientName}
                onChange={setClientName}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxGroup label='Main queries'>
                {MAIN_QUERIES.map((query, index) => {
                  const checked = mainQueries.includes(index)
                  return (
                    <Checkbox
                      key={index}
                      checked={checked}
                      label={query}
                      onChange={(value) => handleQueryChange(index, value)}
                    />
                  )
                })}
              </CheckboxGroup>
            </Grid>
            <Grid item xs={12} cssClass={Style.formElement}>
              <p className={Style.formLabel}>Supplementary queries</p>
              <p className={Style.formInfo}>
                Any supplementary query that you would like an answer to, based on the document's contents. E.g. "What
                is the main scope of work?"
                <br />
                <b>Make sure to put each query in a new line.</b>
              </p>
              <FormInput multiline value={query} onChange={setQuery} placeholder='Enter one or more queries' />
            </Grid>
            <Grid item xs={12} cssClass={Style.formElement}>
              <p className={Style.formLabel}>Document to process</p>
              <p className={Style.formWarning}>
                If you have any doubts regarding the sensitivity and/or privacy of the information you are uploading
                please do not upload.
              </p>
              <Uploader
                accept={['.pdf']}
                maxSize={200}
                fileName={filename}
                onFileChange={onFileChange}
                fileUrl={documentUrl}
                uploading={uploading}
                clearUploader={trigger}
              />
            </Grid>
            <Grid item xs={12} cssClass={Style.submit}>
              <Button label='Submit' onClick={handleSendRequest} disabled={!!formError} />
              {!!formError && (
                <p className={Style.formError}>
                  You need to provide: <b>{formError}</b>
                </p>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <Modal
        isShowing={showModal}
        onClose={handleCloseModal}
        actions={[{ label: 'Close', onClick: handleCloseModal, disabled: uploading }]}
      >
        {uploading && (
          <>
            <h1>Sending request</h1>
            <p>Please wait, your request is being sent to the server for processing.</p>
          </>
        )}
        {!uploading && (
          <>
            <h1>Request sent</h1>
            <p>
              You request has been sent to the server. When processing is ready you'll recive an email with a link to
              the report. The current wait time is approximately {queueLength ? queueLength * WAIT_TIME : WAIT_TIME}min.
            </p>
          </>
        )}
        {uploadError && (
          <>
            <h1>Upload error</h1>
            <p>There was an issue sending the request.</p>
          </>
        )}
      </Modal>
    </Page>
  )
}

export default Home
